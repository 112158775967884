.organization-select {
  &.dropdown {
    > .dropdown-trigger {
      width: 100%;
    }
    > .dropdown-menu {
      width: 100%;
    }
  }
}
