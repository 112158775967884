$primary-dark-blue: #004b78;
$ct-mat-error-color: #ff5722;

:root {
  /* Warning */
  --ct-warning-color: #ffb54d;

  /* Danger */
  --ct-danger-color: #ff4b55;
  --ct-danger-hover-color: #f24236;

  /* Success */
  --ct-success-color: #51c44980;
  --ct-success-color-bright: #51c449;
  --ct-success-color-bright-hover: #4cb844;

  /* Hover Form Field */
  --ct-hover-form-field: #d3d3d3;

  /* Primary Font Color */
  --ct-primary-font-color: #292f33;

  /* Primary Border Color */
  --ct-primary-border-color: #ced2d5;

  /* Primary Blue Interaction Color */
  --ct-primary-blue-color: #005f96;
  --ct-primary-dark-blue-color: #{$primary-dark-blue};
  --ct-primary-blue-pressed: #024166;

  /* Primary Highlight Color */
  --ct-primary-highlight-color: #37afb9;

  /* Font Color Variant / Disabled Buttons */
  --ct-font-color-variant-or-disabled-buttons: #79838c;

  /* Border Variant Color */
  --ct-border-variant-color: #e6e6e6;

  /* Hover Variant Color */
  --ct-hover-variant-color: #f0f0f0;
  --ct-hover-icon-text-color: #dedede;

  /* Background Variant Color */
  --ct-background-variant-color: #f5f5f5;

  /* Navbar Height */
  --ct-navbar-height: 50px;
}

$gray-1: var(--ct-primary-font-color);
$gray-2: var(--ct-font-color-variant-or-disabled-buttons);
$gray-3: var(--ct-primary-border-color);
$gray-4: var(--ct-border-variant-color);
