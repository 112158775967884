.registration-overview-filter {
  height: 100%;
  display: flex;
  flex-direction: column;

  .form-area {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .button-area {
    z-index: 1;
    box-shadow: 0em -0.5em 1em white
  }

  .form-row {
    margin: 1em;
  }

  .checkbox, .radio {
    > input {
      margin-right: 0.5em;
    }
  }

  .organization-select {
    width: 100%;
  }

  .button-row {
    display: flex;

    button {
      border-radius: 0;
    }
  }
}

