.license-plate {
  font-family: GL-Nummernschild-Mtl, GL-Nummernschild-Mtl, GL-Nummernschild-Mtl;
  border: 0.14em solid black;
  border-radius: 0.5em;
  height: 3.5em;
  display: flex;
  overflow: hidden;
  &.grayscale {
    filter: grayscale(1);
  }
  >.highlight {
    width: 1.6em;
    background-color: var(--ct-primary-blue-color);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 0.2em;
    >.stars {
      border-radius: 50%;
      border: #ffb54d dotted 2px;
      height: 1em;
      width: 1em;
    }
    >.country {
      color: white;
      font-size: 1.4em;
      line-height: 1;
    }
  }
  >.plate {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 0.3em;
    >.separator {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 1em;
      >.top {
        border: 1px solid black;
        border-radius: 50%;
        width: 0.75em;
        height: 0.75em;
      }
      >.bottom {
        margin-top: 0.1em;
        border: 1px solid black;
        border-radius: 50%;
        width: 0.9em;
        height: 0.9em;
        overflow: hidden;
      }
    }
    >input {
      font-family: GL-Nummernschild-Mtl, GL-Nummernschild-Mtl, GL-Nummernschild-Mtl;
      border: none;
      font-size: 3em;
      &.distinguishing-sign {
        width: 0.6em;
        &.l-2 {
          width: 1.05em;
        }
        &.l-3 {
          width: 1.5em;
        }
      }
      &.identification {
        width: 0.6em;
        &.l-2 {
          width: 1.05em;
        }
      }
      &.identification-number {
        width: 0.6em;
        &.l-2 {
          width: 1.05em;
        }
        &.l-3 {
          width: 1.5em;
        }
        &.l-4 {
          width: 1.85em;
        }
      }
      &:focus-visible {
        outline: none;
      }
    }
  }
  >.addition {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 0.66em;
    font-size: 3em;
    text-align: center;
    line-height: 1;
  }
  >.season {
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1em;
    font-size: 1em;
    line-height: 1;
    display: flex;
    padding-right: 0.1em;
    >.start, >.end {
      >input {
        font-family: GL-Nummernschild-Mtl, GL-Nummernschild-Mtl, GL-Nummernschild-Mtl;
        padding: 0;
        border: none;
        font-size: 1em;
        width: 1em;
        text-align: center;
        &:focus-visible {
          outline: none;
        }
      }
    }
    &>.start {
      border-bottom: 1px solid black;
    }
  }
}
