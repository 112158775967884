.field-auto-complete {
  div.input {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }
  .dropdown {
    .dropdown-item {
      padding: 0.4em;
    }
    width: 100%;
    > .dropdown-trigger {
      width: 100%;
    }
    > .dropdown-menu {
      width: 100%;
      border: 1px solid var(--ct-primary-border-color);
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 9em;
    }
  }
}
