tbody.user-table {
  .material-symbols-outlined {
    &.active {
      color: var(--ct-success-color-bright)
    }
    &.inactive {
      color: var(--ct-danger-color)
    }
  }

  tr {
    &.inactive {
      background-color: var(--ct-hover-variant-color);
      td {
        color: var(--ct-primary-border-color);
      }
    }
  }

  .empty-colum {
    text-align: center;
  }
}