.document-element {
  position: relative;
  >.loading-mask {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--ct-background-variant-color);
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    > .button {
      background: none;
      border: none;
    }
  }

  &:not(:first-of-type) {
    padding-top: 1em;
    border-top: 1px solid var(--ct-primary-border-color);
    margin-top: 2em;
  }
  >.document-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 0.5em;
  }

  .document-data {
    display: flex;
    align-items: center;
    padding: 1em;
    border: 1px dotted var(--ct-primary-border-color);
    > * {
      display: flex;
    }
    .document-name {
      cursor: pointer;
      margin-right: 1em;
      padding: 0.3em;
      &:hover {
        background-color: var(--ct-hover-variant-color);
      }
      .material-symbols-outlined {
        color: var(--ct-primary-blue-color)
      }
    }
    .document-delete {
      color: var(--ct-danger-color);
      margin-right: 2em;
      cursor: pointer;
      &:hover {
        background-color: var(--ct-hover-variant-color);
      }
    }
    .document-success {
      cursor: default;
      color: var(--ct-success-color-bright);
    }
    .document-info {
      cursor: default;
      color: var(--ct-primary-highlight-color);
    }
    .document-warning {
      cursor: default;
      color: var(--ct-warning-color)
    }
    .document-error {
      cursor: default;
      color: var(--ct-danger-color)
    }
  }
}
