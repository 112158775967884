.app-header {
  display: flex;
  padding: 0.9em 1em;
  background-color: var(--ct-background-variant-color);
  border-bottom: 1px solid var(--ct-primary-border-color);
  align-items: center;
  height: 4.35em;
  .header-title {
    display: flex;
    align-items: center;
    font-size: 1.4em;
    font-weight: 900;
  }
  .spacer {
    flex: 1;
  }
}