@use "variables";

:root {
  --bulma-family-primary: Roboto,Helvetica Neue,sans-serif;
  --bulma-body-family: Roboto,Helvetica Neue,sans-serif;
  --bulma-radius: 0.2rem;
  --bulma-radius-medium: 0.375rem;
  --bulma-radius-large: 0.5rem;

  --bulma-primary-h: 202;
  --bulma-primary-s: 100%;
  --bulma-primary-l: 29%;
  --bulma-primary-invert-l: var(--bulma-primary-100-l);

  --bulma-link-h: var(--bulma-primary-h);
  --bulma-link-s: var(--bulma-primary-s);
  --bulma-link-l: var(--bulma-primary-l);
  --bulma-link-invert-l: var(--bulma-link-100-l);

  --bulma-danger-h: 357;
  --bulma-danger-s: 100%;
  --bulma-danger-l: 65%;
  --bulma-danger-invert-l: var(--bulma-danger-100-l);

  --bulma-warning-h: 35;
  --bulma-warning-s: 100%;
  --bulma-warning-l: 65%;
  --bulma-warning-invert-l: var(--bulma-warning-100-l);

  --bulma-sucess-h: 116;
  --bulma-success-s: 51%;
  --bulma-success-l: 53%;
  --bulma-success-invert-l: var(--bulma-success-100-l);

  --bulma-info-h: 185;
  --bulma-info-s: 54%;
  --bulma-info-l: 47%;
  --bulma-info-invert-l: var(--bulma-info-100-l);

  --bulma-column-gap: 0.5rem;
}

.column {
  padding: var(--bulma-column-gap);
}

.field {
  position: relative;
  .label {
    color: var(--ct-font-color-variant-or-disabled-buttons);
    position: relative;
    font-size: 0.6em;
    z-index: 5;
    background-color: white;
    display: table;
    line-height: 1em;
    left: 1em;
    margin: 0 0 -0.5em;
    padding: 0 0.3em;
  }
  &.required {
    .label {
      &:after {
        content: '*';
      }
    }
  }
}

.panel {
  --bulma-panel-heading-size: 0.85em;
  --bulma-panel-heading-padding: 0.5em 0.9em;
  --bulma-panel-heading-line-height: 1.25em;
  --bulma-panel-heading-weight: 700;
  --bulma-panel-h: 0;
  --bulma-panel-s: 0%;
  --bulma-panel-heading-background-l: 90%;
  --bulma-panel-heading-color-l: 45%;
  --bulma-panel-radius: var(--bulma-radius);
  --bulma-panel-shadow: none;
  border: 1px solid var(--ct-primary-border-color);

  .panel-block {
    > * {
      width: 100%
    }
  }
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'), url('fonts/Roboto-Regular.woff2') format('woff2'),
  url('fonts/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Light'), local('Roboto-Light'), url('fonts/Roboto-Light.woff2') format('woff2'),
  url('fonts/Roboto-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'), url('fonts/Roboto-Medium.woff2') format('woff2'),
  url('fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'), url('fonts/Roboto-Bold.woff2') format('woff2'),
  url('fonts/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Black'), local('Roboto-Black'), url('fonts/Roboto-Black.woff2') format('woff2'),
  url('fonts/Roboto-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'GL-Nummernschild-Mtl';
  src: local('GL-Nummernschild-Mtl'), local('GL-Nummernschild-Mtl'),
  url('fonts/GL-Nummernschild-Mtl.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  tr {
    &.disabled {
      filter: blur(0.5px) opacity(0.8) grayscale(1);
    }
    td {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.button.compact-button {
  padding-left: 0;
  padding-right: 0;
}
