.registration-overview-list {
  .col-license-plate {
    >.wrapper {
      font-size: 0.4em;
      display: flex;
      padding-top: 0.2em;
    }
  }
  th {
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }
  a {
    color: var(--ct-primary-font-color);
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }
  .status {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .sortable {
    cursor: pointer;
    user-select: none;
  }

  .empty-colum {
    text-align: center;
  }
  .row-archived {
    filter: blur(0.5px) opacity(0.5);
    &:hover {
      filter: none;
    }
  }
}
