.city-select {
  >div {
    display: flex;
    .field-zipcode {
      padding-right: 0.5em;
    }
    .field-city {
      padding-left: 0.5em;
      flex: 1;
    }
  }
  .zipcode {
    width: 7em;
  }
}
