.user-detail-tab {
  padding: 0 1em;
  > div {
    padding-bottom: 0.3em;
  }
  .label {
    display: inline;
    margin-right: 0.3em;
  }
}
