.registration-overview-page {
  flex: 1;
  display: flex;
  .filter {
    $width: 26em;
    position: absolute;
    right: -$width;
    width: $width;
    background-color: white;
    transition: right 0.2s;
    height: calc(100vh - 7.8em);
    z-index: 2;
    border-left: 0.1em solid var(--ct-primary-border-color);
    box-shadow: -0.5em 0em 1em var(--ct-primary-border-color);

    &.expanded {
      right: 0em;
    }
  }
  .registration-overview-list {
    flex: 1;
    display: flex;
  }
}
.filter-is-active {
  color: var(--ct-danger-color);
}
