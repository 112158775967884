.autocomplete {
  .options{
    position: absolute;
    z-index: 1;
    width: 100%;
    max-height: 6em;
    overflow: auto;
    :hover {
      background-color: var(--ct-hover-variant-color);
    }
  }
}
