.scroll-table {
  position: relative;
  flex: 1;
  .scroller {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  thead {
    top: 0;
    position: sticky;
    background-color: var(--ct-background-variant-color)
  }
}