@use "../../variables";
.page-loading-mask {
  &.modal {
    --bulma-modal-background-background-color: color-mix(in srgb, var(--ct-primary-font-color) 40%, transparent);
  }
  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .button {
    --bulma-loading-color: white;
    border:none;
    background: none;
    height: 1.5em;
    font-size: 3em;
  }
  .info-text {
    color: white;
  }
}
