.pager {
  padding: 0.5em;
  display: flex;
  > .buffer {
    width: 5em;
  }
  > .paging-wrapper {
    flex: 1;
  }
  > .page-size {
    display: flex;
    align-items: end;
    flex-direction: column;
  }
  .paging-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    >.status {
      padding: 0 0.5em;
    }
  }
}
