.organization-list-element {
  &.disabled {
    filter: blur(0.5px) opacity(0.8) grayscale(1);
  }
  padding: 15px 5px;
  display: flex;
  .name {
    flex: 1;
    display: flex;
    height: 1.5em;
    overflow: visible;
    >* {
      margin-left: 1em;
    }
  }
  .info {
    >* {
      margin-left: 0.3em;
    }
  }
}
