.registration-office-portal-availability-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.3em;
  >.button.is-loading {
    border: none;
    background: none;
  }
}
