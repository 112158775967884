.upload-element {
  position: relative;
  .drop-zone {
    border: 1px dotted var(--ct-primary-border-color);
    padding: 1em;
  }
  .loading-mask {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--ct-background-variant-color);
    display: flex;
    justify-content: center;
    > .button {
      background: none;
      border: none;
    }
  }
}
