.tenant-selection-page {
  padding: 2em 0;
  .panel-block {
    >button {
      &.switch-tenant {
        margin-right: 1em;
      }
      &.logout {
        margin-left: 1em;
      }
    }
  }
  .item {
    border: 1px solid var(--ct-primary-border-color);
    border-radius: 5px;
    padding: 0.5em;
    margin-bottom: 1em;
    cursor: pointer;

    &:hover {
      border-color: var(--ct-danger-color);
    }
    &:active {
      border-color: var(--ct-danger-hover-color);
    }
    &.selected {
      border-color: var(--ct-warning-color);
    }
  }
}
