:root {
  .modal {
    --bulma-modal-card-head-padding: 1.4rem;
    --bulma-modal-card-foot-background-color: var(--bulma-modal-card-head-background-color);
  }
}
.modal-card-head {
  box-shadow: none;
}
.modal-card-foot {
  justify-content: flex-end;
}

div.modal-card-title {
  display: flex;
}

.modal-card-body {
  overflow: visible;
}
