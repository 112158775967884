.organization-list-group {
  padding-left: 1px;
  border-left: 3px solid var(--ct-primary-highlight-color);
  border-bottom: 1px solid var(--ct-primary-border-color);
  >.element {
    display: flex;
    align-items: center;
    >* {
      flex: 1;
    }
    &.expandable {
      cursor: pointer;
      .expander {
        flex: none;
        display: flex;
      }
    }
  }
}