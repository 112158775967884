.navbar {
  background-color: #e6e6e6;
  border-bottom: 1px solid #ced2d5;
  .navbar-brand {
    width: 12em;
  }
  .initials-text {
    border-radius: 50%;
    border: 2px solid;
    height: 2em;
    width: 2em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .navbar-start {
    .button {
      background: #ced2d5;
      box-shadow: none;
    }
    .navbar-item {
      .button {
        .material-symbols-outlined {
          color: #000;
          filter: invert(22%) sepia(66%) saturate(2858%) hue-rotate(184deg) brightness(90%) contrast(102%);
        }

        font-weight: 700;
        font-size: 0.9em;
      }
    }
  }
  .navbar-item {
    img {
      height: 19px;
    }
  }
}
