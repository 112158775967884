.growl {
  position: fixed;
  right: 1em;
  top: 3.5em;
  z-index: 30;
  .notification {
    min-width: 25em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    .button-wrapper {
      padding-left: 1em;
    }
    >.delete {
      top: -0.3em;
      inset-inline-end: -0.3em;
    }
    >.progress {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 0.25em;
      border-radius: 0;
      filter: brightness(90%);
    }
    >.progress[value]::-webkit-progress-value {
      transition: width 0.1s linear;
    }
  }
}
