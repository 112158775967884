.registration-notification {
  display: flex;
  align-items: center;
  padding: 0.9em;
  color: white;
  font-weight: 700;
  &.INFO {
    background-color: var(--ct-primary-blue-color);
  }
  &.SUCCESS {
    background-color: var(--ct-success-color-bright);
  }
  &.ERROR {
    background-color: var(--ct-danger-color);
  }
  &.WARNING {
    background-color: var(--ct-warning-color);
  }
  >.icon {
    padding-right: 0.9em;
  }
  >.notification-message {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  >.action {

  }
}
